<template>
  <div>
    <ResellerHeader :walletCredits="credits" />
    <v-container
      class="gameContainer"
      style="margin: auto; padding-bottom: 50px"
    >
      <h2
        style="color: white; text-align: center; margin: 10px; margin-top: 50px"
      >
        Welcome <span>{{ resellerName }}</span>
      </h2>
      <p style="color: white">{{ date }}</p>
      <v-container class="d-flex flex-row justify-content-center" full-height>
        <!-- Games Nav Sidebar -->
        <div class="games">
          <ul>
            <li
              class="game"
              v-for="(game, index) in gamesArray"
              :class="{ active: index === 0 }"
              :key="game.gameId"
              :value="game"
              :id="game.gameId"
            >
              <b></b>
              <b></b>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <button @click="gameSelected(game)" v-bind="attrs" v-on="on">
                    <span class="icon">
                      <img :src="game.icon" class="image" />
                    </span>
                    <!-- <span class="title">{{ game.name }}</span> -->
                  </button>
                </template>
                <span>{{ game.name }}</span></v-tooltip
              >
            </li>
          </ul>
        </div>

        <!-- Enter Details Area -->
        <div class="details">
          <!-- Game Title -->
          <div class="gameTitle">
            {{ selectedGame.name }}
          </div>
          <!-- Enter PID -->
          <PlayerID @onclick="submitPID" :metadata="selectedPlayerId" />
          <!-- Select Package -->
          <SelectPackage
            @onSubmit="grantPackage"
            @onBack="backToPidForm"
            :playerName="playerName"
            :playerId="playerId"
            :packagesArray="packagesArray"
          />
        </div>
      </v-container>
    </v-container>

    <ResellerPlayerTransactions
      :transactions="transactionsArr"
      :isLoading="!transactionsLoaded"
      @pageChanged="handlePageChange"
      :page="page"
      :totalPages="totalPages"
      @download="downloadRechargeTransactions"
      @search="searchRechargeTransactions"
      @transactionClicked="onTransactionClick"
    />
    <p>
      By using the website, you are agreeing to Moonfrog Labs
      <router-link
        class="footer-link"
        :to="{ name: 'distributorTnC' }"
        target="_blank"
      >
        Terms &amp; Conditions
      </router-link>
    </p>

    <!-- Spinner -->
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import PlayerID from "@/components/PlayerID";
import SelectPackage from "@/components/SelectPackage";
import ResellerHeader from "@/components/ResellerHeader";
import ResellerPlayerTransactions from "@/components/ResellerPlayerTransactions";
import * as client from "@/client/webclient";
import * as PaymentClient from "@/client/PaymentClient";
import * as SageUtils from "@/utils/SageUtils";
import * as SageConstants from "@/config/SageConstants";

export default {
  name: "newhome",
  components: {
    PlayerID,
    SelectPackage,
    ResellerHeader,
    ResellerPlayerTransactions,
  },

  props: {
    fromSignUp: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      credits: 0,
      resellerName: "Reseller", //reseller name
      date: "",
      resellerCountryCode: "",

      isLoading: true,
      gamesArray: [],
      selectedGame: {},
      playerId: "",
      playerName: "",
      packagesArray: [],
      selectedPackage: {},
      selectedPackageAmount: 0,
      selectedPlayerId: "",

      transactionsArr: [],
      transactionsLoaded: false,
      page: 1,
      totalPages: 0,
    };
  },

  created() {
    if (this.fromSignUp) this.$router.go();
    this.init();
    this.date = SageUtils.getTodayDate();
  },

  methods: {
    async init() {
      try {
        // loading credits
        const resellerData = await client.getReseller(
          this.$store.state.account.email,
          this.$store.state.account.email
        );
        this.resellerName = resellerData.name;
        this.resellerCountryCode = resellerData.countryCode
          ? resellerData.countryCode
          : "IN";

        this.credits = await client.getMoonfrogCredits(
          this.$store.state.account.email,
          this.$store.state.account.email
        );

        // loading txn table
        let dates = [
          SageUtils.getDateFromToday(SageConstants.MAX_DATE_RANGE),
          SageUtils.getDateFromToday(0),
        ];
        let txnData = await client.getResellerPlayerTransactions(
          this.$store.state.account.email,
          this.page,
          this.$store.state.account.email,
          this.getDateRangeObj(dates)
        );
        this.transactionsArr = txnData.txns;
        this.totalPages = txnData.totalPages;
        this.transactionsLoaded = true;

        // loading games list
        this.gamesArray = await client.getGames(
          this.$store.state.account.email
        );
        this.selectedGame = this.gamesArray[0];
        document.getElementById("pid").style.display = "block";

        // setting vue cache
        this.$store.commit("setCountryCode", this.resellerCountryCode);
        if (!this.$store.state.creditExchangeRate[this.resellerCountryCode]) {
          const creditExchangeRate =
            await PaymentClient.getCreditExchangeRate();
          this.$store.commit("setCreditExchangeRate", creditExchangeRate);
        }
      } catch (e) {
        this.showConnectionErrorDialog(e);
      } finally {
        this.isLoading = false;
      }
    },

    gameSelected(game) {
      document.getElementById("pid").style.display = "block";
      document.getElementById("packages").style.display = "none";
      this.selectedPlayerId = "";
      this.playerId = "";
      this.playerName = "";
      this.packagesArray = [];
      this.selectedPackage = {};
      this.selectedGame = game;
      let list = document.querySelectorAll(".game");
      for (let i of list) {
        i.className =
          i.getAttribute("id") == game.gameId ? "game active" : "game";
      }
    },

    async submitPID(playerId) {
      this.playerId = playerId;
      this.isLoading = true;
      try {
        //   apiResponse: {playerName: string, isValid: boolean, packages: []}
        let apiResponse = await client.getPackagesForPID(
          playerId,
          this.selectedGame.gameId,
          this.$store.state.account.email
        );
        if (!apiResponse.isValid) throw Error("Player ID is not valid.");
        this.playerName = apiResponse.playerName;
        this.packagesArray = apiResponse.packages;
        for (const element of this.packagesArray) {
          element.name +=
            "(" +
            SageConstants.currencyCountry[this.resellerCountryCode] +
            " " +
            SageUtils.getAmountExclusiveTaxes(
              element.priceInMfCredits.toFixed(2),
              this.$store.state.creditExchangeRate[this.resellerCountryCode]
            ) +
            " + tax)";
        }
        document.getElementById("pid").style.display = "none";
        document.getElementById("packages").style.display = "block";
      } catch (e) {
        this.showConnectionErrorDialog(e);
      } finally {
        this.isLoading = false;
      }
    },

    backToPidForm() {
      this.gameSelected(this.selectedGame);
    },

    async grantPackage(pkgObj) {
      this.selectedPackage = pkgObj.pkg;
      this.selectedPackageAmount = pkgObj.amount;
      try {
        let cancel = false;
        await this.$swal
          .fire({
            title: "Are you sure?",
            html: this.getPlayerConfirmationText(
              this.playerName,
              this.playerId,
              this.selectedPackage,
              this.selectedPackageAmount
            ),
            icon: "warning",
            confirmButtonText: "Confirm",
            showCancelButton: true,
          })
          .then((result) => {
            if (!result.isConfirmed) {
              cancel = true;
            }
          });
        if (cancel) return;
        this.isLoading = true;
        const priceReceived = Math.ceil(
          SageUtils.getAmountInclusiveTaxes(
            this.selectedPackage.priceInMfCredits.toFixed(2),
            this.resellerCountryCode,
            this.$store.state.creditExchangeRate[this.resellerCountryCode]
          )
        );
        let apiResponse = await client.grantPackage(
          this.playerId,
          this.selectedPackage,
          priceReceived,
          this.selectedPackageAmount,
          this.$store.state.account.email
        );

        if (apiResponse.status === "SUCCESS") {
          this.$swal
            .fire({
              icon: "success",
              text: "Purchase Success",
              confirmButtonText: "OK",
            })
            .then(() => {
              this.$router.go();
            });
        } else {
          this.$swal
            .fire({
              icon: "warning",
              text: "Purchase Pending/unsuccessful",
              confirmButtonText: "OK",
            })
            .then(() => {
              this.$router.go();
            });
        }
      } catch (e) {
        this.showConnectionErrorDialog(e);
      } finally {
        this.isLoading = false;
      }
    },

    getPlayerConfirmationText(name, pid, pkgDetails, amount) {
      const game = pkgDetails.gameName;
      const numberOfUnits = amount ? amount : 1;
      const price = pkgDetails.priceInMfCredits * numberOfUnits;

      const x = Math.ceil(
        SageUtils.getAmountInclusiveTaxes(
          price.toFixed(2),
          this.resellerCountryCode,
          this.$store.state.creditExchangeRate[this.resellerCountryCode]
        )
      );
      const content = document.createElement("p");
      content.style.cssText = "word-break:break-all";

      content.innerHTML =
        "Please Confirm Recharge <br><br><b><u>Player Details:</u><b>";
      if (name) {
        content.innerHTML += "<br>Player Name: <b>" + name + "</b>";
      }
      content.innerHTML +=
        "<br>Player ID: <b>" +
        pid +
        "<br><br><u>Recharge Details:</u><br></b>Game: <b>" +
        game +
        "</b><br>Pack: ";
      if (!pkgDetails.isFixedValue)
        content.innerHTML += "<b>" + numberOfUnits + "</b> ";
      content.innerHTML +=
        "<b>" +
        pkgDetails.name +
        "</b><br>" +
        pkgDetails.description +
        "<br>MRP: <b>" +
        SageUtils.getCurrency(this.resellerCountryCode) +
        " " +
        Math.ceil(
          SageUtils.getAmountInclusiveTaxes(
            price.toFixed(2),
            this.resellerCountryCode,
            this.$store.state.creditExchangeRate[this.resellerCountryCode]
          )
        ) +
        "</b><br>MF Credits: <b>" +
        price.toFixed(2) +
        "</b>";
      return content;
    },

    async downloadRechargeTransactions(dateRange) {
      this.isLoading = true;
      try {
        await client.getTransactionsExcel(
          this.$store.state.account.email,
          this.$store.state.account.email,
          "recharge",
          this.getDateRangeObj(dateRange)
        );
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
      }
    },

    async handlePageChange(queryData) {
      this.isLoading = true;
      this.transactionsLoaded = false;
      this.page = queryData.pageNo;
      try {
        let txnData = await client.getResellerPlayerTransactions(
          this.$store.state.account.email,
          this.page,
          this.$store.state.account.email,
          this.getDateRangeObj(queryData.dates)
        );

        this.transactionsArr = txnData.txns;
        this.totalPages = txnData.totalPages;
        this.transactionsLoaded = true;
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
      }
    },

    async searchRechargeTransactions(dates) {
      this.isLoading = true;
      this.transactionsLoaded = false;
      this.page = 1;
      try {
        let txnData = await client.getResellerPlayerTransactions(
          this.$store.state.account.email,
          this.page,
          this.$store.state.account.email,
          this.getDateRangeObj(dates)
        );
        this.transactionsArr = txnData.txns;
        this.totalPages = txnData.totalPages;
        this.transactionsLoaded = true;
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
      }
    },

    async onTransactionClick(data) {
      await this.gameSelected({ gameId: data.gameId, name: data.gameName });
      this.selectedPlayerId = data.targetPid;
    },

    showConnectionErrorDialog(error) {
      this.$swal.fire({
        icon: "error",
        text: error,
        confirmButtonText: "OK",
      });
    },

    getDateRangeObj(dateRange) {
      if (!dateRange) return {};
      if (dateRange.length === 1)
        return { from: dateRange[0], to: dateRange[0] };
      else if (dateRange.length === 2)
        return { from: dateRange[0], to: dateRange[1] };
      else return {};
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

body {
  min-height: 100vh;
  background: #fff;
}

.gameContainer {
  margin: auto;
  padding-bottom: 50px;
  padding-left: 15px;
  padding-right: 15px;
}

.details {
  position: relative;
  top: 20px;
  bottom: 20px;
  /* left: 90px; */
  /* right: 20px; */
  width: 500px;
  border-radius: 10px;
  box-sizing: initial;
  background: #fff;
  transition: width 0.5s;
  overflow-x: hidden;
  /* margin: auto; */
}

.gameTitle {
  margin: 10%;
  font-size: 30px;
  font-weight: 600;
  color: #007bff;
}

.games {
  position: relative;
  top: 20px;
  /* left: 20px; */
  /* right: 520px; */
  bottom: 20px;
  height: 400px;
  width: 70px;
  border-radius: 10px;
  box-sizing: initial;
  border-left: 5px;
  /* border-left: 5px solid #4d5bf9; */
  /* background: #4d5bf9; */
  transition: width 0.5s;
  overflow-x: hidden;
  /* margin: auto; */
}
.games.active {
  width: 300px;
}
.games ul {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-left: 5px;
  padding-top: 40px;
}
.games ul li {
  position: relative;
  list-style: none;
  width: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  margin-bottom: 20px;
}

.games ul li.active {
  background: #fff;
}
.games ul li b:nth-child(1) {
  position: absolute;
  top: -20px;
  height: 20px;
  width: 100%;
  background: #fff;
  display: none;
}
.games ul li b:nth-child(1)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-bottom-right-radius: 20px;
  /* background: #4d5bf9; */
  background: rgba(26, 23, 77, 0.77);
}
.games ul li b:nth-child(2) {
  position: absolute;
  bottom: -20px;
  height: 20px;
  width: 100%;
  background: #fff;
  display: none;
}
.games ul li b:nth-child(2)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-top-right-radius: 20px;
  /* background: #4d5bf9; */
  background: rgba(26, 23, 77, 0.77);
}
.games ul li.active b:nth-child(1),
.games ul li.active b:nth-child(2) {
  display: block;
}
.games ul li button {
  position: relative;
  display: block;
  width: inherit;
  display: flex;
  text-decoration: none;
  color: #fff;
}
.games ul li.active button {
  color: #333;
}
.games ul li button .icon {
  position: relative;
  display: block;
  width: inherit;
  height: 60px;
  line-height: 70px;
  text-align: center;
  margin: auto;
}
.games ul li button .icon .image {
  font-size: 1.5em;
  max-width: 45px;
  transform: translateY(-13%);
}

@media (max-width: 510px) {
  .games ul {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-left: 0px;
    padding-top: 40px;
  }
  .games ul li button .icon .image {
    font-size: 1.5em;
    max-width: 35px;
    transform: translateY(-13%);
  }
}

.games ul li button .title {
  position: relative;
  display: block;
  padding-left: 10px;
  height: 60px;
  line-height: 60px;
  white-space: normal;
}

p {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.7;
  text-align: center;
  color: white;
}

.footer-link {
  text-align: center;
  font-weight: bold;
  color: #007bff;
}

.footer-link:hover {
  text-decoration: underline;
}
</style>