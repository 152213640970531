var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"packages",style:(_vm.immediateRender ? 'display: block' : 'display: none'),attrs:{"id":"packages"}},[_c('strong',{staticStyle:{"margin":"0% 10%","-webkit-box-decoration-break":"clone"}},[_vm._v(" Select a Package for: "+_vm._s(_vm.playerName)+" ("+_vm._s(_vm.playerId)+") ")]),_c('br'),_c('div',{staticClass:"packageTypes"},[_c('v-chip-group',{staticStyle:{"display":"-webkit-inline-box","overflow":"scroll"},attrs:{"active-class":"green white--text","mandatory":"","column":""},model:{value:(_vm.packageTypeSelected),callback:function ($$v) {_vm.packageTypeSelected=$$v},expression:"packageTypeSelected"}},_vm._l((_vm.packageTypesArray),function(type){return _c('div',{key:type,attrs:{"value":type}},[_c('v-chip',{staticClass:"packageTypeChip ma-2",attrs:{"id":type,"label":""}},[_vm._v(" "+_vm._s(type)+" ")])],1)}),0)],1),_c('div',{staticClass:"packageChips"},[_c('v-chip-group',{attrs:{"active-class":"green white--text","mandatory":"","column":""},model:{value:(_vm.packageSelected),callback:function ($$v) {_vm.packageSelected=$$v},expression:"packageSelected"}},_vm._l((_vm.packagesArray),function(pkg){return _c('div',{key:pkg.packageId,attrs:{"value":pkg}},[(pkg.inventoryType === _vm.packageTypesArray[_vm.packageTypeSelected])?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-chip',_vm._g(_vm._b({staticClass:"packageChip ma-2",class:[
                                                                                      pkg.description.substring(0, 5) === 'Offer'
                                                                                        ? 'purple--text text--darken-4'
                                                                                        : '',
                                                                                    ],attrs:{"id":pkg.packageId}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(pkg.name)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(pkg.name))])]):_vm._e()],1)}),0),(
          _vm.packagesArray &&
          _vm.packagesArray[_vm.packageSelected] &&
          !_vm.packagesArray[_vm.packageSelected].isFixedValue
        )?_c('div',{staticClass:"input-group col-lg-12 mb-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.packageAmount),expression:"packageAmount"}],staticClass:"form-control bg-white border-md",attrs:{"id":"packageAmount","type":"text","placeholder":"Enter Amount"},domProps:{"value":(_vm.packageAmount)},on:{"input":function($event){if($event.target.composing)return;_vm.packageAmount=$event.target.value}}})]):_vm._e(),_c('div',{staticClass:"pkg-btn-grp"},[_c('v-btn',{staticClass:"pkg-btn",attrs:{"color":"grey","id":"back-btn"},on:{"click":_vm.goBack}},[_vm._v(" Back ")]),_c('v-btn',{staticClass:"pkg-btn",attrs:{"color":"primary","id":"submit-btn"},on:{"click":_vm.submit}},[_vm._v(" Proceed ")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }