<template>
  <div class="col-md-9 col-lg-8 m-auto mt-5 pid" id="pid" :style="immediateRender ? 'display: block' : 'display: none'">
    <form id="pid-form" action="#">
      <div class="row" style="padding: 0 10px">
        <!-- Player ID -->
        <div class="input-group col-lg-12 mb-4">
          <div class="input-group-prepend">
            <span
              class="
                input-group-text
                bg-white
                px-4
                border-md border-right-0
                playerId
              "
            >
              <v-icon>mdi-account</v-icon>
            </span>
          </div>
          <input
            v-model="playerId"
            @paste.prevent
            id="playerId"
            type="text"
            placeholder="Player ID"
            class="form-control bg-white border-left-0 border-md playerId"
          />
        </div>
        <!-- Confirm Player ID -->
        <div class="input-group col-lg-12 mb-4">
          <div class="input-group-prepend">
            <span
              class="
                input-group-text
                bg-white
                px-4
                border-md border-right-0
                playerId2
              "
            >
              <v-icon>mdi-account-check</v-icon>
            </span>
          </div>
          <input
            v-model="confirmPlayerId"
            id="playerId2"
            type="text"
            placeholder="Confirm Player ID"
            class="form-control bg-white border-left-0 border-md playerId2"
            @paste.prevent
          />
        </div>
        <p
          style="
            margin: 0;
            padding-top: 0;
            padding-bottom: 0;
            color: red;
            display: none;
          "
          class="col-lg-12"
          id="errText-pid"
        >
          {{ errText }}
        </p>
        <!-- Submit Button -->
        <v-btn
          color="primary"
          @click="submit"
          id="back-btn"
          class="submit-btn"
          style="width: 150px; margin: auto"
        >
          Proceed
        </v-btn>
      </div>
    </form>
  </div>
</template>

<script>
// import ActionButton from "@/components/ActionButton";
import * as SageUtils from "@/utils/SageUtils";

export default {
  name: "PlayerID",
  components: {
    // ActionButton,
  },

  props: {
    metadata: {
      type: String,
    },
    immediateRender : {
      type:Boolean,
      default : false
    }
  },

  watch: {
    metadata: function (newVal, oldVal) {
      this.playerId = this.metadata;
      this.confirmPlayerId = this.metadata;
    },
  },

  data() {
    return {
      playerId: "",
      confirmPlayerId: "",
      errText: "",
    };
  },

  methods: {
    submit() {
      this.playerId = this.playerId.trim();
      this.confirmPlayerId = this.confirmPlayerId.trim();

      if (!this.playerId) {
        this.errText = "Player ID field cannot be empty.";
        document.getElementById("errText-pid").style.display = "block";
        SageUtils.showWarningBorder("playerId");
        SageUtils.showWarningBorder("playerId2");
      } else if (this.playerId !== this.confirmPlayerId) {
        this.errText = "Confirm Player ID is different.";
        document.getElementById("errText-pid").style.display = "block";
        SageUtils.showWarningBorder("playerId");
        SageUtils.showWarningBorder("playerId2");
      } else {
        SageUtils.showNormalBorder("playerId");
        SageUtils.showNormalBorder("playerId2");
        document.getElementById("errText-pid").style.display = "none";
        this.$emit("onclick", this.playerId);
        this.playerId = "";
        this.confirmPlayerId = "";
      }
    },
  },
};
</script>
