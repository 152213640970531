<template>
    <div class="packages" id="packages" :style="immediateRender ? 'display: block' : 'display: none'">
        <strong style="margin: 0% 10%; -webkit-box-decoration-break: clone">
                                                                          Select a Package for: {{ playerName }} ({{ playerId }})
                                                                        </strong>
        <br />
        <div class="packageTypes">
            <v-chip-group v-model="packageTypeSelected" active-class="green white--text" style="display: -webkit-inline-box; overflow: scroll" mandatory column>
                <div v-for="type in packageTypesArray" :key="type" :value="type">
                    <v-chip class="packageTypeChip ma-2" :id="type" label>
                        {{ type }}
                    </v-chip>
                </div>
            </v-chip-group>
        </div>
        <div class="packageChips">
            <v-chip-group v-model="packageSelected" active-class="green white--text" mandatory column>
                <div v-for="pkg in packagesArray" :key="pkg.packageId" :value="pkg">
                    <v-tooltip right v-if="pkg.inventoryType === packageTypesArray[packageTypeSelected]">
                        <template v-slot:activator="{ on, attrs }">
                                                                                  <v-chip
                                                                                    class="packageChip ma-2"
                                                                                    :class="[
                                                                                      pkg.description.substring(0, 5) === 'Offer'
                                                                                        ? 'purple--text text--darken-4'
                                                                                        : '',
                                                                                    ]"
                                                                                    :id="pkg.packageId"
                                                                                    v-bind="attrs"
                                                                                    v-on="on"
                                                                                  >
                                                                                    <!-- <v-chip
                                                                                    class="packageChip ma-2"
                                                                                    :id="pkg.packageId"
                                                                                    v-bind="attrs"
                                                                                    v-on="on"
                                                                                  > -->
                                                                                    {{ pkg.name }}
                                                                                  </v-chip>
</template>
            <span>{{ pkg.name }}</span>
          </v-tooltip>
        </div>
      </v-chip-group>
      <div
        class="input-group col-lg-12 mb-4"
        v-if="
          packagesArray &&
          packagesArray[packageSelected] &&
          !packagesArray[packageSelected].isFixedValue
        "
      >
        <input
          v-model="packageAmount"
          id="packageAmount"
          type="text"
          placeholder="Enter Amount"
          class="form-control bg-white border-md"
        />
      </div>
      <!-- Buttons -->
      <div class="pkg-btn-grp">
        <v-btn color="grey" @click="goBack" id="back-btn" class="pkg-btn">
          Back
        </v-btn>
        <v-btn color="primary" @click="submit" id="submit-btn" class="pkg-btn">
          Proceed
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: "PlayerID",

    props: {
        playerName: {
            type: String,
        },
        playerId: {
            type: String,
        },
        packagesArray: {
            type: Array,
        },
        immediateRender: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            packageSelected: 0,
            packageAmount: 0,
            packageTypeSelected: 0,
        };
    },

    computed: {
        packageTypesArray: function() {
            return [...new Set(this.packagesArray.map((item) => item.inventoryType))];
        },
    },

    methods: {
        submit() {
            let pkgArrayOfSelectedType = [];
            for (let element of this.packagesArray) {
                if (
                    element.inventoryType ===
                    this.packageTypesArray[this.packageTypeSelected]
                ) {
                    pkgArrayOfSelectedType.push(element);
                }
            }
            this.$emit("onSubmit", {
                pkg: pkgArrayOfSelectedType[this.packageSelected],
                amount: this.packageAmount,
            });
        },

        goBack() {
            this.$emit("onBack");
        },
    },
};
</script>

<style scoped>
.packageTypes {
    margin: 0% 5%;
    padding-top: 10px;
}

.packageChips {
    margin: 0% 5%;
    padding-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.packageChip {
    width: 209px;
    justify-content: center;
}

.pkg-btn-grp {
    display: flex;
    flex-wrap: wrap;
}

.pkg-btn {
    width: 150px;
    margin: 5px;
}
</style>